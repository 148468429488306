import {
  Route,
  Link as ReactLink,
  BrowserRouter,
  Redirect,
} from "react-router-dom";
import React, { useState } from "react";
import {
  HStack,
  VStack,
  Text,
  Link,
  Checkbox,
  Divider,
  Image,
  useColorModeValue,
  IconButton,
  Icon,
  Pressable,
  Center,
  Hidden,
  StatusBar,
  Stack,
  Box,
  Input,
  ScrollView,
} from "native-base";
import logo from "@assets/icons/aim_logo.png";
import { Button, Modal } from "antd";
import { AiFillEye, AiOutlineEye } from "react-icons/ai";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import { useTranslation } from "react-i18next";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
  userLogin,
} from "@services/redux";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { retrieveItem, storeItem } from "@helpers/storage";
import { marginLeft } from "styled-system";
import { ProjectName } from "@helpers/constants";
import ApkDownloadButton from "./apk_download_button";

const LoginForm = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { showForgotPasswordForm, showLoginForm } = props;
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPass, setShowPass] = React.useState(false);

  const {
    status,
    error,
    token,
    user_id,
    name,
    roles,
    gender,
    loading: loginLoading,
  } = useDynamicSelector("user_login");
  useEffect(() => {
    if (status === "success") {
      storeItem("token", token);
      storeItem("name", name);
      storeItem("user_id", user_id);
      storeItem("gender", gender);
      let admin = false;
      let evaluator = false;
      if (roles) {
        admin = roles?.some((x) => x.name === "admin");
        evaluator = roles?.some((x) => x.name === "evaluator");
      }
      if (token && admin) {
        navigate(ROUTES.DASHBOARD);
        toast.success(`Welcome ${name}`);
      } else if (token && evaluator) {
        navigate(ROUTES.EVALUATORS_DASHBOARD);
        toast.success(`Welcome ${name}`);
      } else {
        toast.error(`Invalid Login`);
      }
      dispatch(dynamicClear("user_login"));
    } else {
      if (error) {
        toast.error(error.message);
        dispatch(dynamicClear("user_login"));
      }
    }
  }, [status]);

  const Login = () => {
    let user_login_key = [{ key: "user_login", loading: true }];
    let user_login_query = userLogin;
    let user_login_variables = { username: userName, password: password };
    dispatch(
      dynamicRequest(user_login_key, user_login_query, user_login_variables)
    );
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      Login();
    }
  };
  return (
    <ScrollView
      contentContainerStyle={{
        flexGrow: 1,
      }}
      style={{
        flex: 1,
      }}
    >
      <VStack
        flex="1"
        px="6"
        py="9"
        _light={{
          bg: "#ffffffe0",
        }}
        _dark={{
          bg: "coolGray.800",
        }}
        space="3"
        justifyContent="space-between"
        borderTopRightRadius={{
          base: "2xl",
          md: "xl",
        }}
        borderBottomRightRadius={{
          base: "0",
          md: "xl",
        }}
        borderTopLeftRadius={{
          base: "2xl",
          md: "0",
        }}
      >
        <VStack space="7">
          <Hidden till="md">
            {/* <HStack alignItems="center"> */}
            <Center>
              <Text
                color={"#396591"}
                bold
                fontSize={{ base: 30, xs: 20, sm: 20, md: 25 }}
                justifyContent="center"
                display="flex"
                fontWeight="normal"
              >
                {ProjectName}
              </Text>
            </Center>
            {/* </HStack> */}
          </Hidden>
          <VStack>
            <VStack space="3">
              <Box mx={5}>
                {/* <Box alignItems={"center"}> */}
                <VStack>
                  <Text
                    pt={2}
                    color={"#64748B"}
                    fontWeight={"600"}
                    fontSize={15}
                  >
                    Username
                  </Text>

                  <Input
                    // variant="rounded"
                    placeholder="Username"
                    onChangeText={(text) => setUserName(text)}
                    value={userName}
                  />
                  {/* </VStack>
                  </Box>
                  <Box alignItems={"center"}>
                    <VStack width={"50%"}> */}
                  <Text
                    pt={2}
                    color={"#64748B"}
                    fontWeight={"600"}
                    fontSize={15}
                  >
                    Password
                  </Text>

                  <Input
                    w={{
                      base: "100%",
                      // xs: "100%",
                      // sm: "100%",
                      // md: "100%",
                      // lg: "100%",
                      // xl: "50%",
                    }}
                    type={showPass ? "" : "password"}
                    // variant="rounded"
                    placeholder="Password"
                    onChangeText={(text) => setPassword(text)}
                    value={password}
                    onKeyPress={handleKeypress}
                    InputRightElement={
                      <IconButton
                        variant="unstyled"
                        icon={
                          showPass ? <AiOutlineEye /> : <AiFillEye />
                          // <Icon
                          //   size="4"
                          //   color="coolGray.400"
                          //   as={Entypo}
                          //   name={showPass ? "eye-with-line" : "eye"}
                          // />
                        }
                        onPress={() => {
                          {
                            !showPass ? setShowPass(true) : setShowPass(false);
                          }
                        }}
                      />
                    }
                  />
                </VStack>
                {/* </Box> */}
              </Box>
              <Link
                ml="auto"
                _text={{
                  fontSize: "xs",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
                _light={{
                  _text: {
                    color: "#499aeb",
                  },
                }}
                _dark={{
                  _text: {
                    color: "#499aeb",
                  },
                }}
                onPress={() => {
                  showForgotPasswordForm(true);
                  showLoginForm(false);
                  // navigate(ROUTES.FORGOT_PASSWORD);
                }}
              >
                {/* Forgot password? */}
              </Link>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{
                    width: "100px",
                  }}
                  loading={loginLoading}
                  type="primary"
                  onClick={() => {
                    if (userName.length && password.length) {
                      Login();
                    } else if (!password.length) {
                      toast.error("Please enter valid Username or Password");
                    }
                  }}
                >
                  SIGN IN
                </Button>
                <ApkDownloadButton />
              </div>{" "}
            </VStack>
          </VStack>
        </VStack>
        <Text>{"            "}</Text>
      </VStack>
    </ScrollView>
  );
};

export default LoginForm;
