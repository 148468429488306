import React from "react";

const ApkDownloadButton = () => {
  const handle_download_apk = () => {
    window.open(
      "https://vultr-nokkam-assets-prod.blr1.vultrobjects.com/photo/2f140837-70ad-4474-90c3-0a57957cbffe.jpg"
    );
  };
  return (
    <button type="apk_button" class="apk_button" onClick={handle_download_apk}>
      <span class="apk_button__text">Download App</span>
      <span class="apk_button__icon">
        <svg
          class="svg"
          data-name="Layer 2"
          id="bdd05811-e15d-428c-bb53-8661459f9307"
          viewBox="0 0 35 35"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M17.5,22.131a1.249,1.249,0,0,1-1.25-1.25V2.187a1.25,1.25,0,0,1,2.5,0V20.881A1.25,1.25,0,0,1,17.5,22.131Z"></path>
          <path d="M17.5,22.693a3.189,3.189,0,0,1-2.262-.936L8.487,15.006a1.249,1.249,0,0,1,1.767-1.767l6.751,6.751a.7.7,0,0,0,.99,0l6.751-6.751a1.25,1.25,0,0,1,1.768,1.767l-6.752,6.751A3.191,3.191,0,0,1,17.5,22.693Z"></path>
          <path d="M31.436,34.063H3.564A3.318,3.318,0,0,1,.25,30.749V22.011a1.25,1.25,0,0,1,2.5,0v8.738a.815.815,0,0,0,.814.814H31.436a.815.815,0,0,0,.814-.814V22.011a1.25,1.25,0,1,1,2.5,0v8.738A3.318,3.318,0,0,1,31.436,34.063Z"></path>
        </svg>
      </span>
    </button>
  );
};

export default ApkDownloadButton;
